import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  activitiesList:any= [];
  clickedImagesArray: any[] = [];
  constructor() { 
    localStorage.getItem('token');
    sessionStorage.getItem('token');
  }
  public api = {
    local: {
      get: this._getLocal,
      save: this._saveLocal,
      remove: this._removeLocal,
      clear: this._clearLocal
    },
    session: {
      get: this._getSession,
      save: this._saveSession,
      remove: this._removeSession,
      clear: this._clearSession
    }
  };
  private _getLocal(key:any) {
    return JSON.parse(localStorage.getItem(key)|| '{}');
  }
  private _saveLocal(key:any, value:any) {
    return localStorage.setItem(key, JSON.stringify(value));
  }
  private _removeLocal(key:any) {
    return localStorage.removeItem(key);
  }
  private _clearLocal() {
    return localStorage.clear();
  }
  private _getSession(key:any) {
    let data: any;
    try {
      data = JSON.parse(sessionStorage.getItem(key)||'{}');
    } catch (e) {
      data = sessionStorage.getItem(key);
    }
    return data;
  }
  private _saveSession(key:any, value:any) {
    return sessionStorage.setItem(key, JSON.stringify(value));
  }
  private _removeSession(key:any) {
    return sessionStorage.removeItem(key);
  }
  private _clearSession() {
    this._saveSession;
    return sessionStorage.clear();
  }
  setActivities(data:any) {
    this.activitiesList.push(data);
  }
  getActivities() { 
    return this.activitiesList;
  }
}
