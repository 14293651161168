import { Injectable } from '@angular/core';
// import { Http, Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Headers, RequestOptions } from "@angular/http";
import { Observable } from 'rxjs';
import 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import { StorageService } from './storage.service';
import { AppSettings } from '../configuration/AppSettings';
import { AuthHttpService } from './auth-http.service';
import { map, catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
export class loginModel {
  public user_name: string;
  public secret_key: string;
  constructor(user_name: string, secret_key: string) {
    this.user_name = user_name;
    this.secret_key = secret_key;
  }
}
export interface TOKEN {
  access_token: string;
  token_type: string;
  expires_in: string;
  user_name: string;
  roles: string;
  '.issued': string;
  '.expires': string;
}
@Injectable()
export class LoginService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  })
  // options: RequestOptions;
  constructor(public _http: AuthHttpService, public http: HttpClient, private _storage: StorageService,public datePipe: DatePipe,) { }

  public login(model: loginModel) {
    let inputJson = {
      // app_name:"DocIT",
      // app_version:"1.0",
      // device_token:"",
      // os_version:"Web",
      password: model.secret_key,
      time: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      app_version : '',
      os_version : "web",
      userName: model.user_name
    };
    // const headers = new HttpHeaders();
    // this.headers.append('content-type', 'application/x-www-form-urlencoded');
    // this.headers.append('withCredentials', 'true');
    // this.headers.set('Access-Control-Allow-Credentials', 'True');
    // this.headers.set('Access-Control-Allow-Origin', '*');
    // this.headers.set('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    // this.headers.set('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token'); 
    // const options  = { headers: this.headers };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    // this.options = new RequestOptions({ headers: this.headers, withCredentials: true });
    let bodyString = window.btoa(JSON.stringify(model));

    const headers :any = {
      'Content-Type': 'application/x-www-form-urlencoded; \
      charset=UTF-8', 'Accept': 'application/json',
      //= 'accept': 'application/json',
      'withCredentials': 'true',
      'Access-Control-Allow-Credentials': 'True',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    };
    if (localStorage.getItem('token') != null) {
      headers['token'] = localStorage.getItem('token');

    }
    // const options = { headers: this.headers };
    return this.http.post(AppSettings.URL.NEW_LOGIN_END_POINT, inputJson, { headers: headers, withCredentials: true });


  }
  public isAuthenticated(): boolean {
    let cookies=document.cookie
    let temp=cookies.split('=')
    let returnValue = temp.includes('refreshToken')
    return returnValue;
  }
  public adminGuard(): boolean {
    let token = this._storage.api.local.get('userType');
    let returnValue = token == 'admin' || token == 'customer' ? true : false;
    return returnValue;
  }
  public teamGuard(): boolean {
    let token = this._storage.api.local.get('userType');
    let returnValue = token == 'teamMember' || token == 'moderator' ? true : false;
    return returnValue;
  }
  private handleLoginError(error: Response) {
    console.error(error);
    // return Observable.throwError(error || 'Server error');
  }
  private handleError(error: Response) {
    console.error(error);
    // return Observable.throwError(error || 'Server error');
  }
}
