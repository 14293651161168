<!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{inputInfo.title? inputInfo.title : 'Message'}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->
  <div class="modal-body modal-data-responsive">
    <div>
      <div class="col-md-12 p-2" *ngIf="inputInfo">
        <div class="row">
           <div class="col-md-12 text-center" *ngIf="inputInfo.icon">
          <img src="assets/images/logo.png" class='logo_img mt-3 mx-auto d-block' alt='logo' />
          </div>
          <div class="col-md-12 p-0 text-center" [ngClass]="{true:'col-md-8',false:'col-md-12'}[inputInfo.icon]"
            *ngIf="inputInfo.data">
            <ul class="message-class p-0 mb-0" [ngClass]="inputInfo.data.length == 1 ? 'alert-body':''"
              *ngIf="inputInfo.data.length > 0">
              <li *ngFor="let info of inputInfo.data;">
                <h3 *ngIf="info.heading" class="mb-0 mt-2 message-class">{{info.heading}}</h3>
                <h5 *ngIf="info.SubHeading" class="message-class m-0">{{info.SubHeading}}</h5>
                <h5 *ngIf="info.SubHeading1" class="message-class retry-color mb-0 mt-2" [innerHTML]="info.SubHeading1"></h5>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-0 d-block">
    <div class="col-md-12 p-0 d-flex justify-content-center">
      <button *ngIf="inputInfo.buttons?.length == 0" class="btn btn-primary col-md-12 btn-modal"
      style="cursor:pointer" (click)="activeModal.close('Save click');" ngbAutofocus>OK</button>
      <ng-container *ngFor="let btnInfo of inputInfo.buttons; let this_index = index">
        <ng-container *ngIf="(this_index+1) === inputInfo.buttons.length else noFocusBtn">
          <button class="btn btn-modal mx-2 col-md-{{btnInfo.cols || 6}}" [ngClass]="btnInfo.class"
          style="cursor:pointer" (click)="activeModal.close(btnInfo.type);" ngbAutofocus>{{btnInfo.label}}</button>
        </ng-container>
        <ng-template #noFocusBtn>
          <button class="btn btn-modal ml-2 col-md-{{btnInfo.cols || 6}}" [ngClass]="btnInfo.class"
          style="cursor:pointer" (click)="activeModal.close(btnInfo.type);">{{btnInfo.label}}</button>
        </ng-template>
      </ng-container>
    </div>
  </div>