import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  { path: 'login/:token', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  { path: 'landing', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),canActivate: [AuthGuardService] },
  // { path: 'projects', loadChildren: () => import('./components/project-list/project-list.module').then(m => m.ProjectListModule) },
  // { path: 'countries', loadChildren: () => import('./components/countries-list/countries-list.module').then(m => m.CountriesListModule) },
  // { path: 'dashboard', loadChildren: () => import('./login-dashboard/login-dashboard-routing.module').then(m => m.LoginDashboardRoutingModule) },
  // { path: 'sidenav', loadChildren: () => import('./components/sidenav/sidenav-routing.module').then(m => m.SidenavRoutingModule) },
  // { path: 'clinic', loadChildren: () => import('./components/clinic/clinic-routing.module').then(m => m.ClinicRoutingModule) },
  // { path: 'patient-file', loadChildren: () => import('./components/patient-file/patient-file-routing.module').then(m => m.PatientFileRoutingModule) },
  // { path: 'polling', loadChildren: () => import('./components/polling/polling-routing.module').then(m => m.PollingRoutingModule) },
  // { path: 'treatment', loadChildren: () => import('./components/treatment/treatment-routing.module').then(m => m.TreatmentRoutingModule) },
  // { path: 'patient-visit', loadChildren: () => import('./components/patientvisit/patientvisit-routing.module').then(m => m.PatientvisitRoutingModule) },
  //  { path: 'chart', loadChildren: () => import('./components/chart-and-vaccination/chart-and-vaccination-routing.module').then(m => m.ChartAndVaccinationRoutingModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
