import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(private router: Router, private _login: LoginService) { }

  canActivate(){
    if (localStorage?.getItem('userDetails')) {
    return true;
    } else {
    this.router.navigate(['/login']);
    return false
    }
  }
}
