import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatBadgeModule } from '@angular/material/badge';
import { HttpClientModule } from '@angular/common/http';
// import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { LoginDashboardComponent } from './login-dashboard/login-dashboard.component';
import { ChartAndVaccinationComponent } from './components/chart-and-vaccination/chart-and-vaccination.component';
import { TreatmentComponent } from './components/treatment/treatment.component';
import { PollingComponent } from './components/polling/polling.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AuthGuardService } from './services/auth-guard.service';
import { LoaderService } from './shared/loader/loader-service.service';
import { SharedService } from './services/shared.service';
import { AlertService } from './services/alert.service';
import { InformationModalService } from './shared/information-modal/information-modal.service';
import { InformationModalComponent } from './shared/information-modal/information-modal.component';
import { LoaderCommonComponent } from './shared/loader/loader-common.component';
import { environment } from '../environments/environment'
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { DatePipe } from '@angular/common'
import { LoginService } from './services/login.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    AppComponent,
    LoginDashboardComponent,
    ChartAndVaccinationComponent,
    TreatmentComponent,
    PollingComponent,
    InformationModalComponent,
    LoaderCommonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatBadgeModule,
    HttpClientModule,
    FormsModule,
    MatRadioModule,
    MatIconModule,
    MatListModule,
    NgSelectModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    MatSlideToggleModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuardService,
    SharedService,
    AlertService,
    // Logger,
    // Options,
    LoaderService,
    InformationModalService,
    DatePipe,
    LoginService,
    // CookieService
  ],
  bootstrap: [AppComponent],
  exports: [LoaderCommonComponent],
})
export class AppModule {}
