import { Injectable } from '@angular/core';
import { AlertService, AlertConfig } from '../services/alert.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { StorageService } from 'app/services/storage.service';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    public alert: AlertService,
    private localStorage: StorageService,
  ) { }

  private showHeader = new Subject<any>();
  
  // common alerts
  public internalCodeError(message?) {
    const alertConf: AlertConfig = {
      type: 'error', title: 'Failed', body: message.error || 'Internal code error'
    };
    this.alert.open(alertConf);
  }
  public serviceErrorMessage(message?) {
    const alertConf: AlertConfig = { type: 'error', title: 'Failed', body: message };
    this.alert.open(alertConf);
  }
  public serviceSuccessMessage(message?) {
    const alertConf: AlertConfig = { type: 'success', title: 'Success', body: message };
    this.alert.open(alertConf);
  }
  public serviceWarningMessage(message?) {
    const alertConf: AlertConfig = { type: 'warning', title: 'Warning', body: message };
    this.alert.open(alertConf);
  }

}

