export const environment = {
  // API: 'http://surveyapp.knowledgelens.com/momentZS_v1.9/',
  // http://momentzs-dev.knowledgelens.com/test_v4.0/
  // API: 'http://momentzs-dev.knowledgelens.com/test_v4.0/',
  // API: 'https://surveyapp.knowledgelens.com/momentZS_v2.0/',
  // API: 'https://api.mzs-ci.dev.zsservices.com/',
  // API: 'https://api.mzs-ci.zsservices.com/',
  API: 'https://mzs.dev.zsservices.com/services/',
  REPORTAPI: 'https://mzs-ci.zsservices.com/reports/',
  // API: 'https://surveyapp.knowledgelens.com/momentZS_v2.0/',
  firebase: {
    apiKey: "AIzaSyBnQGQT3Q7ygh6ln3DOrsqKK0l5wVFbhsY",
    authDomain: "g0166-zs-momentzs-app.firebaseapp.com",
    databaseURL: "https://g0166-zs-momentzs-app-default-rtdb.firebaseio.com",
    projectId: "g0166-zs-momentzs-app",
    storageBucket: "g0166-zs-momentzs-app.appspot.com",
    messagingSenderId: "683344372195",
    appId: "1:683344372195:web:264728b4a95ce7094880d6",
    measurementId: "G-8V40XKPYFN"
  },
  production: true
};
